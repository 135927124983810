<template>
  <div class="flex-fill d-flex flex-column justify-content-center py-4">
    <div class="container-tight py-6">
      <form class="card card-md" v-on:submit.prevent="loginSubmit">
        <div class="card-body">
          <h2 class="card-title text-center mb-4">Авторизация</h2>
          <div class="mb-3">
            <label class="form-label">Email адрес</label>
            <input type="email" :class="{ 'is-invalid': formError.email }" class="form-control" placeholder="Enter email" v-model.trim="email" />
            <fieldErrors v-bind:errors="formError.email"></fieldErrors>
          </div>
          <div class="mb-2">
            <label class="form-label"> Пароль </label>
            <input type="password" :class="{ 'is-invalid': formError.password }" class="form-control" placeholder="Password" autocomplete="off" v-model.trim="password" />
            <fieldErrors v-bind:errors="formError.password"></fieldErrors>
          </div>
          <input type="hidden" :class="{ 'is-invalid': formError.globalerr }" />
          <fieldErrors v-bind:errors="formError.globalerr"></fieldErrors>
          <input type="hidden" :class="{ 'is-invalid': error }" />
          <fieldErrors v-bind:errors="[error]"></fieldErrors>
          <div class="form-footer">
            <button type="button" v-on:click="loginSubmit" class="btn btn-primary w-100">Войти</button>
          </div>
          <div class="form-footer">
            <router-link class="btn btn-primary w-100" to="recovery-pass">Восстановить пароль</router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import fieldErrors from "../../components/fieldErrors.vue";

export default {
  name: "Login",
  components: {
    fieldErrors,
  },
  data: () => ({
    email: "",
    password: "",
    error: "",
    formError: Object,
  }),
  computed: {
    loginStatus() {
      return localStorage.getItem("authStatus");
    },
  },
  methods: {
    async loginSubmit() {
      const response = await fetch("/auth/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
        }),
      });
      if (response.ok) {
        var data = await response.json();
        localStorage.setItem("userIni", data.ini);
        localStorage.setItem("is_superuser", data.is_superuser);
        localStorage.setItem("authStatus", true);
        this.$router.push({ name: "limits" }).catch(() => {});
      } else {
        if (response.status == "400") this.error = "Неверный логин или пароль";
        else if (response.status == "500") this.error = "Ошибка API";
        else {
          this.error = "Неизвестная ошибка";
        }
      }
    },
  },
};
</script>

<style>
.inner {
  position: absolute;
  top: 10%;
  border: 1px solid #404040;
}
</style>