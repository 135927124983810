<template>
  <div class="card">
    <ul class="nav nav-tabs" data-bs-toggle="tabs">
      <li class="nav-item">
        <a href="#tabs-home-13" class="nav-link active" data-bs-toggle="tab">
          <span class="nav-link-icon d-md-none d-lg-inline-block">
            <i class="fa fa-list"></i>
          </span>
          <span class="nav-link-title"> Список </span>
        </a>
      </li>
      <li class="nav-item ms-auto right-btns">
        <p
          class="btn btn-primary"
          @click="
            showModal = true;
            modalSource = '/limits/pays/add/';
          "
        >
          <i class="fa fa-plus"></i> Добавить
        </p>
        <p class="btn btn-info" @click="$emit('print')">
          <i class="fa fa-print"></i> Печать
        </p>
        <p class="btn btn-info" @click="$emit('saveXLS')">
          <i class="fa fa-file-excel-o"></i> XLS
        </p>
      </li>
    </ul>

    <div class="table-responsive">
      <table class="table card-table table-vcenter text-nowrap table-hover datatable">
        <thead>
          <tr>
            <th class="text-center">Дата</th>
            <th>Организация</th>
            <th class="text-center">КБК</th>
            <th>Подрядчик</th>
            <th>Договор</th>
            <th class="text-center">Сумма</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in rows"
            v-bind:key="row.id"
            @click="
              showModal = true;
              modalSource = '/limits/pays/' + row.id + '/';
              $router.push({ name: 'pay', params: { id: row.id } }).catch(() => {});
            "
          >
            <td>{{ row.date }}</td>
            <td>{{ row.org }}</td>
            <td class="text-center text-monospace">{{ row.kbk }}</td>
            <td>{{ row.contractor }}</td>
            <td>{{ row.contract }}</td>
            <td class="text-end">{{ row.total }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Paginator :paginator="paginator"></Paginator>
    <Form v-if="showModal" @close="hideModalForm" :source="modalSource"></Form>
  </div>
</template>

<script>
import Form from "./Form.vue";
import Paginator from "../../components/Paginator.vue";

export default {
  name: "DataTable",
  data: function () {
    return {
      showModal: false,
      modalSource: "",
    };
  },
  props: {
    rows: Array,
    paginator: Object,
  },
  components: {
    Form,
    Paginator,
  },
  mounted() {
    this.showModalForm();
  },
  methods: {
    showModalForm() {
      var id = this.$route.params.id;
      if (id >= 0) {
        this.showModal = true;
        this.modalSource = "/limits/pays/" + id + "/";
      }
    },
    hideModalForm() {
      this.showModal = false;
      this.$emit("reloadTable");
    },
  },
};
</script>

<style>
td.ulichkom {
  max-width: 350px;
  overflow: hidden;
}
</style>