<template>
  <layout>
    <div class="row row-cards">
      <div class="col-9">
        <router-view 
          :rows="rows"
          :paginator="paginator"
          @reloadTable="reloadTable"
          @setPage="setPage"
          @saveXLS="saveXLS"
          @print="print"
        ></router-view>
      </div>
      <div class="col-3">
        <SearchPanel @searchFormFilter="queryFitler"></SearchPanel>
      </div>
    </div>
  </layout>
</template>



<script>
import SearchPanel from "./SearchPanel.vue";
import Layout from "../../layouts/Base.vue";

export default {
  components: {
    SearchPanel,
    Layout,
  },
  data: function () {
    return {
      rows: [],
      paginator: {},
      query_search: "",
      query_page: "",
      base_url: "/limits/contracts/list/",
    };
  },
  methods: {
    queryFitler(params) {
      var search_params = new URLSearchParams(params);
      this.query_page = "";
      this.query_search = search_params.toString();
      this.reloadTable();
    },
    setPage(page) {
      this.query_page = "page=" + page;
      this.reloadTable();
    },
    reloadTable() {
      var source = this.base_url + "?";
      if (this.query_search) source += "&" + this.query_search;
      if (this.query_page) source += "&" + this.query_page;
      fetch(source)
        .then((response) => response.json())
        .then((data) => {
          this.rows = data.results;
          this.paginator = data.meta.paginator;
        });
    },
    saveXLS(){
      location.href = this.base_url + "?format=xlsx&" + this.query_search;
    },
    print(){
      window.open(this.base_url + "?format=print&" + this.query_search);
    },
  },
  mounted() {
    this.reloadTable();
  },
};
</script>