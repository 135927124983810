<template>
<div class="card">
  <div class="card-header">
    <h3 class="card-title">Поиск</h3>
  </div>
  <div class="card-body">
    <form>
      <div class="row mb-3">
        <div class="form-group">
          <label class="form-label">Дата</label>
          <div>
            <date-picker v-model="searchForm.date" format='DD.MM.YYYY' valueType="format"></date-picker>
          </div>
        </div>
      </div>
      <div class="form-group mb-3 ">
        <label class="form-label">Организация</label>
        <select class="form-select" v-model="searchForm.org">
          <option v-for="org in orgs" :value="org.id" :key="org.id">
            {{ org.name }}
          </option>
        </select>          
      </div>
      <div class="form-group mb-3 ">
        <label class="form-label">КБК</label>
        <div>
          <input type="text" class="form-control" v-model="searchForm.kbk">
        </div>
      </div>
      <div class="form-group mb-3 ">
        <label class="form-label">Наименование</label>
        <div>
          <input type="text" class="form-control" v-model="searchForm.description">
        </div>
      </div>
      <div class="form-footer">
        <button type="button" class="btn btn-primary" @click="search()">
          <i class="fa fa-search"></i> Найти
        </button>
        <button type="button" class="btn btn-default pull-right" @click="resetForm()">
          Очистить
        </button>
      </div>
    </form>
  </div>
</div>
</template>


<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
  name: 'SearchPanel',
  components: {
    DatePicker,
  },
  data: function () {
    return {
      searchForm: {},
      orgs: [],
    };
  },
  mounted() {
    fetch("/limits/orgs/")
      .then((response) => response.json())
      .then((data) => {
        this.orgs = data;
      });
  },
  methods: {
    search() {
      this.$emit('searchFormFilter', this.searchForm);
    },
    resetForm(){
      this.searchForm = {};
      this.search();
    }
  }
}
</script>