<template>
  <ul class="navbar-nav">
    <li class="nav-item" v-bind:class="{ active: $route.name == 'limits' }">
      <router-link :to="{ name: 'limits' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" /><path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" /></svg>
        </span>
        <span class="nav-link-title"> Лимиты</span>
      </router-link>
    </li>

    <li class="nav-item" v-bind:class="{ active: $route.name == 'corrections' }">
      <router-link :to="{ name: 'corrections' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="21" y1="17" x2="3" y2="17" />
            <path d="M6 10l-3 -3l3 -3" />
            <line x1="3" y1="7" x2="21" y2="7" />
            <path d="M18 20l3 -3l-3 -3" />
          </svg>
        </span>
        <span class="nav-link-title"> Перемещения</span>
      </router-link>
    </li>

    <li class="nav-item" v-bind:class="{ active: $route.name == 'contracts' }">
      <router-link :to="{ name: 'contracts' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 4v8h3.586a1 1 0 0 1 .707 1.707l-6.586 6.586a1 1 0 0 1 -1.414 0l-6.586 -6.586a1 1 0 0 1 .707 -1.707h3.586v-8a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1z" /></svg>
        </span>
        <span class="nav-link-title"> Принятые обязательства</span>
      </router-link>
    </li>

    <li class="nav-item" v-bind:class="{ active: $route.name == 'pay' }">
      <router-link :to="{ name: 'pay' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 20v-8h-3.586a1 1 0 0 1 -.707 -1.707l6.586 -6.586a1 1 0 0 1 1.414 0l6.586 6.586a1 1 0 0 1 -.707 1.707h-3.586v8a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /></svg>
        </span>
        <span class="nav-link-title"> Исполненные обязательства</span>
      </router-link>
    </li>

    <li class="nav-item" v-bind:class="{ active: $route.name == 'reports' }" v-if="is_superuser">
      <router-link :to="{ name: 'reports' }" class="nav-link">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><line x1="9" y1="17" x2="9" y2="12" /><line x1="12" y1="17" x2="12" y2="16" /><line x1="15" y1="17" x2="15" y2="14" /></svg>
        </span>
        <span class="nav-link-title"> Отчёты</span>
      </router-link>
    </li>



  </ul>
</template>




<script>
export default {
  name: "Topmenu",
  data: () => ({
    is_superuser: localStorage.getItem("is_superuser") == 'true',
  }),
  props: {},
};
</script>