<template>
  <div class="card" v-on:keyup.esc="showModal = false">
    <ul class="nav nav-tabs" data-bs-toggle="tabs">
      <li class="nav-item">
        <a href="#tabs-home-13" class="nav-link active" data-bs-toggle="tab">
          <span class="nav-link-icon d-md-none d-lg-inline-block">
            <i class="fa fa-list"></i>
          </span>
          <span class="nav-link-title"> Список </span>
        </a>
      </li>
      <li class="nav-item ms-auto right-btns">
        <p
          class="btn btn-primary"
          @click="
            showModal = true;
            modalSource = '/limits/add/';
          "
          v-if="hasAddPerm"
        >
          <i class="fa fa-plus"></i> Добавить
        </p>
        <p class="btn btn-info" @click="$emit('print')">
          <i class="fa fa-print"></i> Печать
        </p>
        <p class="btn btn-info" @click="$emit('saveXLS')">
          <i class="fa fa-file-excel-o"></i> XLS
        </p>
      </li>

    </ul>

    <div class="table-responsive">
      <table class="table card-table table-vcenter text-nowrap table-hover datatable">
        <thead>
          <tr>
            <th>              
              <TableOrderLink field="org__name" :ordering="ordering" @change_ordering="change_ordering">Организация</TableOrderLink>
            </th>
            <th class="text-center">
              <TableOrderLink field="kbk" :ordering="ordering" @change_ordering="change_ordering">КБК</TableOrderLink>
            </th>
            <th class="text-end">
              <TableOrderLink field="plan" :ordering="ordering" @change_ordering="change_ordering">План</TableOrderLink>
            </th>
            <th class="text-end">
              Корр. план
            </th>
            <th class="text-end">
              Принято обяз.
            </th>
            <th class="text-end">
              Остаток
            </th>
            <th class="text-end">
              <TableOrderLink field="pay_total" :ordering="ordering" @change_ordering="change_ordering">Исполнено обяз.</TableOrderLink>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in rows"
            v-bind:key="row.id"
            @click="
              showModal = true;
              modalSource = '/limits/' + row.id + '/';
              $router.push({ name: 'limits', params: { id: row.id } }).catch(() => {});
            "
          >
            <td>
                {{ row.org_name }}<br />
                <span style="font-size: 0.75em;color: #999;">{{ row.description }}</span>
            </td>
            <td class="text-center text-monospace">{{ row.kbk }}</td>
            <td class="text-end">{{ row.plan }}</td>
            <td class="text-end">{{ row.corrected_plan }}</td>
            <td class="text-end">{{ row.contract_total }}</td>
            <td class="text-end">{{ row.remains }}</td>
            <td class="text-end">{{ row.pay_total }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <Paginator :paginator="paginator"></Paginator>
    <Form v-if="showModal" @close="hideModalForm" :source="modalSource"></Form>
  </div>
</template>

<script>
import Form from "./Form.vue";
import Paginator from "../../components/Paginator.vue";
import TableOrderLink from "../../components/TableOrderLink.vue";

export default {
  name: "DataTable",
  data: function () {
    return {
      showModal: false,
      modalSource: "",
      ordering: "",
    };
  },
  props: {
    rows: Array,
    paginator: Object,
    table_source: String,
    hasAddPerm: Boolean,
  },
  components: {
    Form,
    Paginator,
    TableOrderLink,
  },
  mounted() {
    this.showModalForm();
  },
  methods: {
    showModalForm() {
      var id = this.$route.params.id;
      if (id >= 0) {
        this.showModal = true;
        this.modalSource = "/limits/" + id + "/";
      }
    },
    hideModalForm() {
      this.showModal = false;
      this.$emit("reloadTable");
    },
    change_ordering(field) {
      if(this.ordering == field){
        this.ordering = "-" + field
      } else {
        this.ordering = field
      }
      this.$emit("setOrder", this.ordering);
    }
  },
};
</script>

<style>
td.ulichkom {
  max-width: 350px;
  overflow: hidden;
}
</style>