<template>
  <div class="modal" tabindex="-1" role="dialog" v-on:keyup.ctrl.enter="save()">
    <div class="modal-dialog modal-xlg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Лимит</h5>
          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link type="button" class="btn-close" :to="{ name: 'limits' }"></router-link>
          </p>
        </div>
        <div class="modal-body">
          <div v-for="item in formError.non_field_errors" :key="item" class="text-danger mb-3">
            {{ item }}
          </div>
          <div class="row mb-3">
            <div class="col-lg-5">
              <label class="form-label">Организация</label>
              <select :class="{ 'is-invalid': formError.org }" class="form-select" v-model="form.org">
                <option v-for="org in form.org_choices" :value="org.id" :key="org.id">
                  {{ org.name }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.org"></fieldErrors>
            </div>
            <div class="col-lg-4">
              <label class="form-label">
                КБК
              </label>
              <input type="text" :class="{ 'is-invalid': formError.kbk }" class="form-control" v-model="form.kbk" />
              <fieldErrors v-bind:errors="formError.kbk"></fieldErrors>
            </div>
            <div class="col-lg-3">
              <label class="form-label">
                Код цели
              </label>
              <input type="text" :class="{ 'is-invalid': formError.target_code }" class="form-control" v-model="form.target_code" />
              <fieldErrors v-bind:errors="formError.target_code"></fieldErrors>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-12">
              <label class="form-label">Наименование</label>
              <textarea :class="{ 'is-invalid': formError.description }" class="form-control" rows="2" v-model="form.description"></textarea>
              <fieldErrors v-bind:errors="formError.description"></fieldErrors>
            </div>
          </div>

          <div class="mb-3">
            <div class="col-lg-4">
              <label class="form-label">Первоначальный план</label>
              <input type="number" :class="{ 'is-invalid': formError.plan }" class="form-control price-input" v-model="form.plan" />
              <fieldErrors v-bind:errors="formError.plan"></fieldErrors>
            </div>
          </div>

          <div class="mb-3">
            <div class="" style="margin: 0 8px" v-if="form.corrections && form.corrections.length">
              <h4>Перемещения лимитов</h4>
              <table class="table card-table table-vcenter text-nowrap table-hover">
                <thead>
                  <th>Дата</th>
                  <th>С КБК</th>
                  <th>На КБК</th>
                  <th class="text-end">Сумма</th>
                </thead>
                <tbody>
                  <tr v-for="correction in form.corrections" :key="correction.id">
                    <td>{{ correction.date }}</td>
                    <td class="text-center text-monospace">{{ correction.kbk_from }}</td>
                    <td class="text-center text-monospace">{{ correction.kbk_to }}</td>
                    <td class="text-end">{{ correction.total }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3" class="text-end">Скорректированный план</td>
                    <td class="text-end">{{ form.corrected_plan }}</td>
                  </tr>
                </tfoot>
              </table>

            </div>
          </div>



        </div>

        <div class="modal-footer">
          <p @click="del()" title="Удалить (Esc)" class="me-auto" v-if="form.has_add_perms">
            <a href="" class="btn"><i class="fa fa-times"></i> Удалить </a>
          </p>
          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn" :to="{ name: 'limits' }"> Закрыть </router-link>
          </p>
          <p @click="save()" v-if="form.has_add_perms">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn btn-primary ms-auto" :to="{ name: 'limits' }">
              <i class="icon fa fa-save"></i> Сохранить
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import fieldErrors from "../../components/fieldErrors.vue";

export default {
  name: "Form",

  components: {
    fieldErrors,
  },

  data: function () {
    return {
      form: {},
      formError: {},
    };
  },
  props: {
    source: String,
  },
  async mounted() {
    const response = await fetch(this.source);
    if (response.ok) {
      this.form = await response.json();
    } else {
      this.$router.push({ name: "streets" });
      this.$emit("close");
    }
  },
  methods: {
    async save() {
      const response = await fetch(this.source, {
        method: "post",
        body: JSON.stringify(this.form),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      if (response.ok) {
        this.$emit("close");
      } else {
        this.formError = await response.json();
      }
    },
    async del(){
      if(confirm('Удалить?')){
        const response = await fetch(this.source, {
          method: "delete",
          credentials: "include",
        });
        if (response.ok) {
          this.$emit("close");
        }
      }
    }
  },
};
</script>
