<template>
  <div class="modal" tabindex="-1" role="dialog" v-on:keyup.ctrl.enter="save()">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Исполненные обязательства</h5>
          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link type="button" class="btn-close" :to="{ name: 'pay' }"></router-link>
          </p>
        </div>
        <div class="modal-body">
          <div v-for="item in formError.non_field_errors" :key="item" class="text-danger mb-3">
            {{ item }}
          </div>
          <div class="row mb-3">
            <div class="col-lg-4">
              <label class="form-label">Дата</label>
              <date-picker v-model="form.date" format='DD.MM.YYYY' valueType="format" :class="{ 'is-invalid': formError.date }"></date-picker>
              <fieldErrors v-bind:errors="formError.date"></fieldErrors>
            </div>            
            <div class="col-lg-4">
              <label class="form-label">Организация</label>
              <select :class="{ 'is-invalid': formError.org }" class="form-select" v-model="form.org" @change="update_contractors()">
                <option v-for="org in form.org_choices" :value="org.id" :key="org.id">
                  {{ org.name }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.org"></fieldErrors>
            </div>
            <div class="col-lg-4">
              <label class="form-label">Подрядчик</label>
              <select :class="{ 'is-invalid': formError.contractor }" class="form-select" v-model="form.contractor" @change="update_contracts()">
                <option v-for="contractor in contractors" :value="contractor.id" :key="contractor.id">
                  {{ contractor.name }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.contractor"></fieldErrors>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-9">
              <label class="form-label">Договор</label>
              <select :class="{ 'is-invalid': formError.contract }" class="form-select" v-model="form.contract">
                <option v-for="contract in contracts" :value="contract.id" :key="contract.id">
                  {{ contract.content }}
                </option>
              </select>
              <fieldErrors v-bind:errors="formError.contract"></fieldErrors>
            </div>
            <div class="col-lg-3">
              <label class="form-label">Сумма</label>
              <input type="number" :class="{ 'is-invalid': formError.total }" class="form-control price-input" v-model="form.total" />
              <fieldErrors v-bind:errors="formError.total"></fieldErrors>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-lg-12">
              <label class="form-label">Комментарий</label>
              <textarea :class="{ 'is-invalid': formError.comment }" class="form-control" rows="3" v-model="form.comment"></textarea>
              <fieldErrors v-bind:errors="formError.comment"></fieldErrors>
            </div>
          </div>


        </div>

        <div class="modal-footer">
          <p @click="del()" title="Удалить (Esc)" class="me-auto">
            <a class="btn"><i class="fa fa-times"></i> Удалить </a>
          </p>
          <p @click="$emit('close')" title="Закрыть (Esc)">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn" :to="{ name: 'pay' }"> Закрыть </router-link>
          </p>
          <p @click="save()">
            <router-link title="Сохранить (Ctrl+Enter)" class="btn btn-primary ms-auto" :to="{ name: 'pay' }">
              <i class="icon fa fa-save"></i> Сохранить
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import fieldErrors from "../../components/fieldErrors.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
  name: "Form",

  components: {
    fieldErrors, DatePicker,
  },

  data: function () {
    return {
      form: {},
      formError: {},
      contractors: [],
      contracts: [],
    };
  },
  props: {
    source: String,
  },
  async mounted() {
    const response = await fetch(this.source);
    if (response.ok) {
      this.form = await response.json();
    } else {
      this.$router.push({ name: "pay" });
      this.$emit("close");
    }
    this.update_contractors()
    this.update_contracts()
  },
  methods: {
    async save() {
      const response = await fetch(this.source, {
        method: "post",
        body: JSON.stringify(this.form),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      if (response.ok) {
        this.$emit("close");
      } else {
        this.formError = await response.json();
      }
    },
    async del(){
      if(confirm('Удалить?')){
        const response = await fetch(this.source, {
          method: "delete",
          credentials: "include",
        });
        if (response.ok) {
          this.$emit("close");
        }
      }
    },
    update_contractors: function(){
      if(this.form.org){
        fetch("/limits/contractors/?org=" + this.form.org)
          .then((response) => response.json())
          .then((data) => {
            this.contractors = data;
          });
      }
    },

    update_contracts: function(){
      if(this.form.contractor){
        fetch("/limits/contracts/list/?contractor=" + this.form.contractor)
          .then((response) => response.json())
          .then((data) => {
            this.contracts = data.results;
          });
      }
    },
  },
};
</script>
