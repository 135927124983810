<template>
  <header class="navbar navbar-expand-md navbar-light d-print-none">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
        {{ name }}
      </h1>

      <div class="navbar-nav flex-row order-md-last">

        <div class="nav-item d-none d-md-flex me-3">
          <select class="form-select" v-model="year" @change="setYear()">
            <option v-for="y in year_choices" :key="y">{{ y }}</option>
          </select>
        </div>

        <div class="nav-item dropdown d-none d-md-flex me-3">
          <span>{{ ini }}</span>
        </div>

        <div class="nav-item dropdown d-none d-md-flex me-3">
          <a href="#" v-on:click="logout">Выйти</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export default {
  name: "Header",
  data: () => ({
    ini: localStorage.getItem("userIni"),
    year: new Date().getFullYear(),
    year_choices: [],
  }),
  props: {
    name: String,
  },
  methods: {
    async logout() {
      const response = await fetch("/auth/logout/", {
        method: "POST",
      });
      if (response.ok) {
        localStorage.removeItem("userIni");
        localStorage.removeItem("is_superuser");
        localStorage.removeItem("authStatus");
        this.$router.push({ name: "login" }).catch(() => {});
      }
    },
    setYear(){
      setCookie("year", this.year, 30)
      window.location.reload()
    }
  },
  mounted() {
    let 
      selected_year = getCookie("year"),
      current_year = new Date().getFullYear();
    this.year = selected_year || current_year
    this.year_choices = []
    for(let year=2021;year<=current_year;year++){
      this.year_choices.push(year);
    }
  },

};
</script>