<template>
  <layout>

    <div class="page-header d-print-none">
      <div class="row align-items-center">
        <div class="col">
          <h2 class="page-title">
            Отчёты
          </h2>
        </div>
        <div class="col-auto ms-auto d-print-none">

        </div>
      </div>
    </div>

    <div class="row row-deck row-cards">
      <div class="col-3"></div>
      <div class="col-3"></div>
      <div class="col-6"></div>
    </div>


  </layout>
</template>


<script>
import Layout from "../../layouts/Base.vue"


export default {
  components: {
    Layout,
  },
}

</script>